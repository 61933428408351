import React from 'react';
import { SvgProps } from './icon.types';

function HelpIcon({ fillColor = '#2D3049' }: SvgProps) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M12 2c5.523 0 10 4.477 10 10 0 5.524-4.477 10-10 10-5.524 0-10-4.476-10-10C2 6.477 6.476 2 12 2zm0 14.48a1 1 0 10-.001 2 1 1 0 000-2zm.017-11A3.87 3.87 0 008.15 9.349h2a1.87 1.87 0 011.867-1.867 1.87 1.87 0 011.867 1.867 1.87 1.87 0 01-1.696 1.859L11 11.206v4.229h2v-2.347a3.873 3.873 0 002.883-3.74 3.87 3.87 0 00-3.867-3.867z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export { HelpIcon };
