import React, { FC } from 'react';
import Autosuggest, { AutosuggestProps, InputProps } from 'react-autosuggest';
import { Input } from '@form/components/Input/Input.component';
import { MagnifierIcon } from '@icons/Magnifier.icon';

import './Autocomplete.scss';

type Props = AutosuggestProps<any, any>;

const Autocomplete: FC<Props> = ({ renderInputComponent, focusInputOnSuggestionClick = false, ...rest }) => {
  const defaultRenderInputComponent = (inputProps: InputProps<any>) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      inputProps.onChange(e, { method: 'type', newValue: e.target.value });
    };

    return (
      <Input
        rightIcon={<MagnifierIcon height={24} width={24} />}
        {...inputProps}
        onChange={onChange}
        spellCheck={false}
      />
    );
  };

  const finalRenderInputComponent = renderInputComponent || defaultRenderInputComponent;

  return (
    <Autosuggest
      focusInputOnSuggestionClick={focusInputOnSuggestionClick}
      renderInputComponent={finalRenderInputComponent}
      {...rest}
    />
  );
};

export { Autocomplete };
