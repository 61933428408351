import React, { ComponentPropsWithRef, FC, forwardRef } from 'react';
import { Text } from '@components/Text/Text.component';
import { TextProps } from '@components/Text/Text.types';

export type LabelProps = {
  label?: JSX.Element | string;
  labelI18n?: string;
  labelFlavour?: TextProps['flavour'];
  labelVariant?: TextProps['variant'];
  required?: boolean;
  labelTag?: TextProps['tag'];
  className?: string;
};

export const Label: FC<LabelProps & ComponentPropsWithRef<'h1'>> = forwardRef(
  (
    { label, labelI18n, labelFlavour, labelVariant = 'h6', required, children, labelTag = 'span', ...rest },
    labelRef
  ) => {
    return label || labelI18n ? (
      <Text
        ref={labelRef}
        flavour={labelFlavour}
        i18nKey={labelI18n}
        tag={labelTag}
        variant={labelVariant as TextProps['variant']}
        {...rest}
      >
        {label} {required && '*'}
        {children}
      </Text>
    ) : null;
  }
);
