import { FormHints, HintProps } from '../FormHints/FormHints.component';
import { HelpIconButton } from '../HelpIconButton/HelpIconButton.component';
import React, { ComponentProps, ComponentPropsWithRef, FC, forwardRef } from 'react';
import { Text } from '@components/Text/Text.component';
import { TextProps } from '@components/Text/Text.types';
import { FormElement } from '@form/business/form.types';
import { Label, LabelProps } from '@form/components/Label/Label.component';
import { useTranslation } from '@locales/useTranslation.hook';
import classnames from 'classnames';

import './Input.scss';

export type InputProps = LabelProps &
  ComponentPropsWithRef<'input'> &
  HintProps &
  FormElement & {
    rightIcon?: React.ReactNode;
    placeholder?: string;
    hintFlavour?: TextProps['flavour'];
    placeholderI18n?: string;
    disabled?: boolean;
    helpIconModalProps?: { show: boolean; titleI18n?: string; message?: JSX.Element | string };
    maxLength?: number;
    hintI18n?: string;
  };

function getExtraDefaultProps(type: InputProps['type']): ComponentProps<'input'> {
  switch (type) {
    case 'email':
      return {
        autoCorrect: 'off',
        spellCheck: 'false',
      };
    default:
      return {};
  }
}

function formatInputValue(type: string | undefined, value: string | number | readonly string[] | undefined) {
  if (value !== '') {
    if (type === 'email') {
      return String(value).replace(/\s/g, '');
    }
    if (type === 'number') {
      return String(value);
    }
  }
  return value;
}

const Input: FC<InputProps> = forwardRef(
  (
    {
      placeholder,
      placeholderI18n,
      label,
      required,
      labelI18n,
      rightIcon,
      validators,
      disabled = false,
      isHintVisible,
      className,
      labelFlavour,
      hintFlavour,
      labelVariant,
      helpIconModalProps = { show: false },
      hintI18n,
      variant = 'full-width',
      maxLength = 100,
      type,
      value,
      pushContentBelow,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation([]);
    const placeholderI18nTrad = placeholderI18n && t(placeholderI18n);
    const rootClassNames = classnames(
      'plc-input',
      'plc-form-element',
      variant,
      isHintVisible && validators && validators.some(v => !v.isValid) && 'error',
      className,
      rightIcon && 'with-right-icon'
    );
    const extra = getExtraDefaultProps(type);

    return (
      <label className={rootClassNames}>
        {(label || labelI18n) && (
          <header className="plc-mb-s">
            <Label
              label={label}
              labelFlavour={disabled ? 'grey' : labelFlavour}
              labelI18n={labelI18n}
              labelVariant={labelVariant}
              required={required}
            />
            {helpIconModalProps.show && (
              <div className="help-icon-container">
                <HelpIconButton popupTitleI18nKey={helpIconModalProps.titleI18n}>
                  {helpIconModalProps.message}
                </HelpIconButton>
              </div>
            )}
          </header>
        )}
        <div className={classnames('input-box-wrapper', { disabled })}>
          <div className={`input-box ${disabled ? 'is-disabled' : 'is-active'}`}>
            <input
              ref={ref}
              {...extra}
              {...rest}
              className="small"
              disabled={disabled}
              maxLength={maxLength}
              placeholder={placeholder || placeholderI18nTrad}
              type={type}
              value={formatInputValue(type, value)}
            />
            {rightIcon}
          </div>
        </div>
        {hintI18n && (
          <Text className="plc-input-hint" flavour={hintFlavour || labelFlavour} i18nKey={hintI18n} variant="tiny" />
        )}
        <FormHints
          isHintVisible={isHintVisible && !disabled}
          pushContentBelow={pushContentBelow}
          validators={validators}
        />
      </label>
    );
  }
);

const InlineInput: FC<ComponentPropsWithRef<'input'> & { variant?: TextProps['variant'] }> = forwardRef(
  ({ className, variant = 'p', ...rest }, ref) => {
    const rootClassNames = classnames('plc-inline-input', variant, className);
    return <input ref={ref} className={rootClassNames} {...rest} />;
  }
);

export { Input, InlineInput };
